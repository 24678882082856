
import { ref } from "vue";
import { editToken } from "@/solana/token";
import { chosenCluster } from "@/solana/connection";
import { AuthorityType } from "@solana/spl-token";
import Toggle from "@/components/util/Toggle.vue";
import * as SolanaErrorHandler from "@/solana/SolanaErrorHandler";
import SecretFormField from "@/components/util/SecretFormField.vue";
import CopyIcon from "@/components/util/CopyIcon.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";
import Heading from "@/components/util/Heading.vue";

export default {
  components: {
    Toggle,
    SecretFormField,
    CopyIcon,
    PublicKeyFormField,
    Heading
  },
  setup() {
    const payerSecret = ref("");
    const feePayerSignsExternally = ref(true);

    const tokenAddress = ref("");
    const editingFreezeAuthority = ref(false);

    const currentAuthority = ref("");
    const currentAuthoritySignsExternally = ref(true);

    const newAuthorityAddress = ref("");
    const editedTokenAddress = ref("");
    const tokenLink = ref("");
    const editingToken = ref(false);
    const errorMessage = ref("");

    const onEditToken = async () => {
      tokenLink.value = "";
      editedTokenAddress.value = "";
      editingToken.value = true;
      errorMessage.value = "";
      try {
        const authorityType: AuthorityType = editingFreezeAuthority.value
          ? "FreezeAccount"
          : "MintTokens";
        await editToken(
          payerSecret.value,
          tokenAddress.value,
          newAuthorityAddress.value,
          currentAuthority.value,
          authorityType,
          feePayerSignsExternally.value,
          currentAuthoritySignsExternally.value
        );
        editedTokenAddress.value = tokenAddress.value;
        tokenLink.value = `https://explorer.solana.com/address/${tokenAddress.value}?cluster=${chosenCluster.value}`;
      } catch (err) {
        errorMessage.value = SolanaErrorHandler.getErrorMessage(err);
      }

      editingToken.value = false;
    };

    return {
      payerSecret,
      newAuthorityAddress,
      currentAuthority,
      editingFreezeAuthority,
      tokenAddress,
      editedTokenAddress,
      onEditToken,
      editingToken,
      tokenLink,
      errorMessage,
      feePayerSignsExternally,
      currentAuthoritySignsExternally
    };
  }
};
